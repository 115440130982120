import type {FabricItemDimensions} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {TimeFormat} from '@Components/table/table.types';
import type {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {FabricObject, Group, Path} from '@postermywall/fabricjs-2';
import {Point} from '@postermywall/fabricjs-2';
import {addItemsToGroupWithOriginalScale} from '@Utils/fabric.util';

export abstract class Layout {
  public abstract layoutType: LayoutTypes;
  public item: TableItem;

  public edgePadding = 10;
  public strokeWidth = 1;

  public abstract doLayout(): Promise<void>;
  public abstract setItems(): Promise<void>;
  public abstract setStylesForHighlightedItems(): void;

  public constructor(item: TableItem) {
    this.item = item;
  }

  public getLayoutName(): string {
    return this.layoutType;
  }

  horizontallyStackItems(group: Group, offset: number, padding: number): void {
    const items = group.getObjects();
    const newDim = this.getNewViewDimensions(group, 'horizontal');
    group.set({
      width: newDim.width + 2 * padding,
      height: newDim.height + (items.length - 1) * offset + 2 * padding,
    });

    for (let i = 0; i < items.length; i++) {
      if (i === 0) {
        items[i].set({
          left: -group.width / 2 + padding,
          top: -group.height / 2 + padding,
        });
      } else {
        items[i].set({
          left: items[i - 1].left,
          top: items[i - 1].top + items[i - 1].height + offset,
        });
      }
    }
  }

  horizontallyCenterItems(group: Group): void {
    const items = group.getObjects();
    for (let i = 0; i < items.length; i++) {
      const newCoords = items[i].getCornerPoints(new Point(0, items[i].top));
      items[i].set({
        left: newCoords.tl.x,
      });
    }
  }

  verticallyCenterItems(group: Group): void {
    const items = group.getObjects();
    for (let i = 0; i < items.length; i++) {
      const newCoords = items[i].getCornerPoints(new Point({x: items[i].left, y: 0}));
      items[i].set({
        top: newCoords.tl.y,
      });
    }
  }

  verticallyStackItems(group: Group, offset: number, padding: number): void {
    const items = group.getObjects();
    const newDim = this.getNewViewDimensions(group, 'vertical');
    group.set({
      width: newDim.width + (items.length - 1) * offset + 2 * padding,
      height: newDim.height + 2 * padding,
    });

    for (let i = 0; i < items.length; i++) {
      if (i === 0) {
        items[i].set({
          left: -group.width / 2 + padding,
          top: -group.height / 2 + padding,
        });
      } else {
        items[i].set({
          left: items[i - 1].left + items[i - 1].width * items[i - 1].scaleX + offset,
          top: items[i - 1].top,
        });
      }
    }
  }

  doRightAlign(group: Group): void {
    const items = group.getObjects();
    const groupWidth = group.width;
    const padding = this.edgePadding;

    for (let i = 0; i < items.length; i++) {
      items[i].set({
        left: groupWidth / 2 - items[i].width - padding * 0.5,
      });
    }
  }

  doLeftAlign(group: Group): void {
    const items = group.getObjects();
    const groupWidth = group.width;
    const padding = this.edgePadding;

    for (let i = 0; i < items.length; i++) {
      items[i].set({
        left: -groupWidth / 2 + padding * 0.5,
      });
    }
  }

  doBottomAlign(group: Group, offset = 11): void {
    const items = group.getObjects();
    const groupHeight = group.height;

    for (let i = 0; i < items.length; i++) {
      items[i].set({
        top: groupHeight / 2 - items[i].height - offset * 0.5,
      });
    }
  }

  getNewViewDimensions(group: Group, val: string): FabricItemDimensions {
    const objects = group.getObjects();
    let minWidth = 0;
    let minHeight = 0;
    let w;
    let h;
    switch (val) {
      case 'vertical':
        for (let i = 0; i < objects.length; i++) {
          minWidth += objects[i].width * objects[i].scaleX;
          h = objects[i].height * objects[i].scaleY;
          if (h > minHeight) {
            minHeight = h;
          }
        }
        break;
      default: // case 'horizontal':
        for (let i = 0; i < objects.length; i++) {
          minHeight += objects[i].height * objects[i].scaleY;
          w = objects[i].width * objects[i].scaleX;
          if (w > minWidth) {
            minWidth = w;
          }
        }
        break;
    }

    return {
      width: minWidth,
      height: minHeight,
    };
  }

  getScaleForMenuIcon(icon: Path, fontSize: number): number {
    return fontSize / Math.max(icon.width, icon.height);
  }

  emptyGroup(group: Group): void {
    group.removeAll();
  }

  getWrappingInfo(): string[][] {
    return [];
  }

  /**
   * Returns the required text after wrapping it accordingly to how it was last saved. Wrapping may vary from browser to browser, we want to retain
   * the same wrapping as when the user last saved their poster.
   */
  getWrapping(model: MenuItem, row: number, text = ''): string {
    if (model.wrappingInfo != null && model.wrappingInfo.length > 0) {
      return model.wrappingInfo[row].join('\n');
    }

    // return the text as it is if wrapping info is not available
    return text;
  }

  /**
   * Returns the scaled stroke value to use for the bold text items in layouts (Name, Price, etc)
   */
  getScaledStrokeWidth(model: TableItem, strokeWidth: number = this.strokeWidth): number {
    return Math.min(strokeWidth / model.fabricObject.scaleX, strokeWidth);
  }

  addLayoutItemsToGroupWithOriginalScale(items: FabricObject[]): void {
    addItemsToGroupWithOriginalScale(this.item.fabricObject, items);
  }
}

export const isMilitaryFormat = (timeFormat: TimeFormat): boolean => {
  return timeFormat === TimeFormat.MILITARY;
};
