export const getCacheBustedURL = (url: string): string => {
  const urlObj = new URL(url);
  urlObj.searchParams.set('ts', new Date().getTime().toString());

  return urlObj.href;
};

export function getURISegment(segmentIndex: number = 0): string | null {
  const path = window.PMW.util.getCurrentPagePath().replace(/^\/(.*)/, '$1');
  const segments = path.split('/');
  const isValidIndex = segmentIndex < segments.length;

  return isValidIndex ? segments[segmentIndex] : null;
}

export const isValidURL = (urlToValidate: string): boolean => {
  if (urlToValidate.split(' ').length > 1) {
    return false;
  }

  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/{2}=]*)/;
  return regex.test(urlToValidate);
};

export const cleanURIComponent = (uri: string): string => {
  return uri
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/-+/g, '-') // Remove duplicate dashes
    .replace(/^-+|-+$/g, ''); // Trim leading and trailing dashes
};

export const hasInvalidCharacters = (urlToValidate: string): boolean => {
  const pattern = /["<>#%{}|^~[\]`]/;
  return pattern.test(urlToValidate);
};

export const getFilenameFromURL = (url: string): string => {
  const splitted = url.split('/');
  return splitted.pop()!;
};

export const isValidClickableLink = (url: string): boolean => {
  return url === '' || window.PMW.util.isValidURLSimple(url) || window.PMW.util.isValidPhoneSimple(url);
};

export const prependProtocol = (url: string): string => {
  const hasExistingProtocol = /^(mailto:|tel:|https?:\/\/)/i.test(url);
  if (hasExistingProtocol) {
    return url;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(url)) {
    return `mailto:${url}`;
  }

  if (window.PMW.util.isValidPhoneSimple(url)) {
    return `tel:${url}`;
  }

  return `https://${url}`;
};

export const getStateFromUrl = (url: string): string => {
  return url.split('state=')[1].split('&')[0];
};

export const doesTextContainGoogleMeetURL = (text: string): boolean => {
  const regex = /https:\/\/meet\.google\.com\/\S+/;
  const match = regex.exec(text);
  return !!match;
};

export const isGoogleMeetLink = (url: string): boolean => {
  let parsedUrl = null;

  try {
    parsedUrl = new URL(url);
  } catch (e) {
    return false;
  }
  const host = parsedUrl.hostname;
  return host === 'meet.google.com' || host.endsWith('.meet.google.com');
};

export const extractGoogleMeetURL = (text: string): string | undefined => {
  const regex = /https:\/\/meet\.google\.com\/\S+/;
  const match = regex.exec(text);
  return match ? match[0] : undefined;
};

export const isZoomLink = (url: string): boolean => {
  let parsedUrl = null;

  try {
    parsedUrl = new URL(url);
  } catch (e) {
    return false;
  }
  const host = parsedUrl.hostname;
  return /\.?zoom\.(us|com)$/.test(host);
};

export const isWebpURL = (url: string): boolean => {
  return url.includes('.webp');
};

export const convertWebpURLToJPG = (url: string): string => {
  if (isWebpURL(url)) {
    return url.replace(/\.webp$/, '.jpg');
  }

  return url;
};
